@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Sylfaen";
  src: url("./Assets/Fonts/sylfaen.ttf");
}
@font-face {
  font-family: "Metropolis";
  src: url("./Assets/Fonts/metropolis/Metropolis-Medium.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-Black.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-BlackItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-Bold.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-BoldItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-ExtraBold.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-ExtraBoldItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-ExtraBoldItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-ExtraLight.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-ExtraLightItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-Light.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-LightItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-MediumItalic.otf");
  src: url("./Assets/Fonts/metropolis/Metropolis-Regular.otf");
}

body {
  overflow-x: hidden;
  max-width: 100%;
  margin: 2px;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sylfaen";
}

.bg {
  /* The image used */
  background-image: url("./Assets/Images/homeImg.png");
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slide1 {
  /* The image used */
  background-image: url("./Assets/Images/slider/slid1.jpg");
  height: 80vh;
  max-width: 100%;
  background-position: contain;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slide2 {
  /* The image used */
  background-image: url("./Assets/Images/slider/slid2.jpg");
  height: 80vh;
  max-width: 100%;
  background-position: contain;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slide3 {
  /* The image used */
  background-image: url("./Assets/Images/slider/slid3.jpg");
  height: 80vh;
  max-width: 100%;
  background-position: contain;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slide4 {
  /* The image used */
  background-image: url("./Assets/Images/slider/slid4.jpg");
  height: 80vh;
  max-width: 100%;
  background-position: contain;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slide5 {
  /* The image used */
  background-image: url("./Assets/Images/slider/slid5.jpg");
  height: 80vh;
  max-width: 100%;
  background-position: contain;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slide6 {
  /* The image used */
  background-image: url("./Assets/Images/slider/slidmaster.png");
  height: 80vh;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.retail-bg {
  /* The image used */
  background-image: url("./Assets/Images/rental.png");
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.impact {
  /* The image used */
  background-image: url("./Assets/Images/chose.png");
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 8px;
}
.meet {
  /* The image used */
  background-image: url("./Assets/Images/meet.png");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 8px;
}
.support {
  /* The image used */
  background-image: url("./Assets/Images/support.png");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 8px;
}
.life {
  /* The image used */
  background-image: url("./Assets/Images/career.png");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 8px;
}
.careerr {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.product {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.productInfo {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.blog {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.about {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.gallery-head {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 2px -1px rgba(0,0,0,0.2);
}
.quote {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.claims {
  /* The image used */
  background-image: url("./Assets/Images/staff/bann2.jpg");
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.content-div:hover .fd-cl {
  opacity: 0.25;
}
.content-div:hover .fd-sh {
  opacity: 1;
}

::-webkit-scrollbar {
  opacity: 0;
  width: 0;
  display: none;
  background-color: transparent;
}
